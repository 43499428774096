// Waitlist.js

import React, { useState } from 'react';
import '../styles/Waitlist.css';
import Header from './Header';

const Waitlist = () => {
    const [formData, setFormData] = useState({ name: '', email: '', company: '' });
    const [message, setMessage] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');
        setIsSubmitted(false);
        setErrors({});

        // Front-end validation
        let validationErrors = {};
        if (!formData.name.trim()) validationErrors.name = 'Name is required.';
        if (!formData.email.trim()) validationErrors.email = 'Email is required.';
        if (!formData.company.trim()) validationErrors.company = 'Company is required.';

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        try {
            const webAppUrl = 'https://script.google.com/macros/s/AKfycbx5zMMwUoUSkAiezLBijg5gz0DzqcG3LEhKG9-sLbSMkO5_Ju_7Flw_HeoPqcx07X2D/exec'; // Replace with your Web App URL

            const response = await fetch(webAppUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                const resData = await response.json();
                if (resData.result === 'success') {
                    setFormData({ name: '', email: '', company: '' });
                    setMessage('You have successfully joined the waitlist!');
                    setIsSubmitted(true);
                } else {
                    throw new Error(resData.error || 'Server error occurred.');
                }

                // Reset submission state after 2 seconds
                setTimeout(() => setIsSubmitted(false), 2000);
            } else {
                // Attempt to parse error message from response
                let errorMsg = 'Network response was not ok.';
                try {
                    const errorData = await response.json();
                    if (errorData.error) {
                        errorMsg = errorData.error;
                    }
                } catch (parseError) {
                    // If parsing fails, retain the default error message
                }
                throw new Error(errorMsg);
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage('An error occurred. Please try again later.');
            setIsSubmitted(false);
        }
    };

    return (
        <div className="waitlist-page">
            <Header showMenuIcon={false} onZuriaClick={() => (window.location.href = '/')} />
            <div className="waitlist-container">
                <h1 className="waitlist-title">Join the Pilot</h1>
                <h4 className="waitlist-subtitle">
                    Build a custom AI suite and deploy it in one click.
                </h4>
                <form className="waitlist-form" onSubmit={handleSubmit}>
                    {/* Name Field */}
                    <div className="form-group">
                        <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Name"
                            value={formData.name}
                            onChange={handleChange}
                            className={`waitlist-input ${errors.name ? 'input-error' : ''}`}
                            required
                        />
                        {errors.name && <span className="error-message">{errors.name}</span>}
                    </div>

                    {/* Email Field */}
                    <div className="form-group">
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                            className={`waitlist-input ${errors.email ? 'input-error' : ''}`}
                            required
                        />
                        {errors.email && <span className="error-message">{errors.email}</span>}
                    </div>

                    {/* Company Field */}
                    <div className="form-group">
                        <input
                            type="text"
                            id="company"
                            name="company"
                            placeholder="Company"
                            value={formData.company}
                            onChange={handleChange}
                            className={`waitlist-input ${errors.company ? 'input-error' : ''}`}
                            required
                        />
                        {errors.company && <span className="error-message">{errors.company}</span>}
                    </div>

                    {/* Submit Button */}
                    <button
                        type="submit"
                        className={`waitlist-submit ${isSubmitted ? 'submitted' : ''}`}
                        disabled={isSubmitted} // Prevent multiple submissions
                    >
                        {isSubmitted ? 'Submitted' : 'Submit'}
                    </button>
                </form>

                {/* Feedback Message */}
                {message && (
                    <p className={`waitlist-message ${isSubmitted ? 'success' : 'error'}`}>
                        {message}
                    </p>
                )}
            </div>
        </div>
    );
};

export default Waitlist;
