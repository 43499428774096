// Header.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Header.css';

const Header = ({ onZuriaClick, showMenuIcon = true }) => {
  const navigate = useNavigate();

  const handleZuriaClick = () => {
    console.log('zuria bubble clicked');
    if (onZuriaClick) {
      // Execute the passed scrollToTop function
      onZuriaClick();
    } else {
      // Default behavior: navigate to the root path
      navigate('/');
    }
  };

  const handleContactClick = () => {
    navigate('/contact');
  };

  return (
    <div className="header-container">
      <div className="zuria-bubble" onClick={handleZuriaClick}>
        <span className="zuria-name">zuria.</span>
      </div>
      <header className="contact">
        <span className="nav-item" onClick={handleContactClick} style={{ cursor: 'pointer' }}>
          contact
        </span>
      </header>
    </div>
  );
};

export default Header;
