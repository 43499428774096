// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Content from './components/Content';
import Waitlist from './components/Waitlist';
import Contact from './components/Contact'; // Import Contact component
import './styles/App.css';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Content />} />
                <Route path="/waitlist" element={<Waitlist />} />
                <Route path="/contact" element={<Contact />} /> {/* New Contact route */}
            </Routes>
        </Router>
    );
}

export default App;
