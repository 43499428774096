// Content.js
import React, { useEffect, useCallback, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate
import '../styles/Content.css';
import Header from './Header';
import titleImg from '../inserts/title.png';
import demoImg from '../inserts/demo.png';
import appsImg from '../inserts/apps.png';

const Content = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Initialize useNavigate
  const topRef = useRef(null);

  useEffect(() => {
    // Scroll to the top smoothly whenever the route changes
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
      console.log('Route changed: scrolling to top');
    }
  }, [location]);

  // Function to scroll to top, to be passed to Header
  const scrollToTop = useCallback(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
      console.log('scrollToTop called');
    }
  }, []);

  const handleJoinPilot = () => {
    navigate('/waitlist');
  };

  return (
    <>
      {/* Pass the scrollToTop function as a prop */}
      <Header onZuriaClick={scrollToTop} />

      {/* Reference point for scrolling */}
      <div ref={topRef}></div>

      <main className="main-content">
        {/* Section 1 */}
        <section className="sec">
          <div className="title">
            <h1 className="top">Embed AI in your business.</h1>
            <h1 className="bottom">
              Supercharge your <span className="italic">microworkflows.</span>
            </h1>
          </div>
          <div className="subtitle">
            <h4 className="top_sub">
              Build a custom AI suite and deploy it in one click.
            </h4>
            {/* Changed from <p> to <button> */}
            <button className="para join-pilot-button" onClick={handleJoinPilot}>
              Join the pilot
            </button>
          </div>
        </section>

        {/* Section 2 */}
        <section className="sec">
          <h2 className="top_sec">We integrate third-party</h2>
          <h2 className="bottom_sec">
            apps into <span className="bold">one intelligence hub.</span>
          </h2>
          <img src={titleImg} alt="title" className="img" />
        </section>

        {/* Section 3 */}
        <section className="sec">
          <h2 className="top_sec">Pick your apps.</h2>
          <h2 className="bottom_sec">
            We'll handle <span className="bold">when to use what.</span>
          </h2>
          <img src={demoImg} alt="demo" className="img" />
        </section>

        {/* Section 4 */}
        <section className="sec">
          <h2 className="top_sec">Keep your existing workflows.</h2>
          <h2 className="bottom_sec">
            <span className="bold">Supercharge them with AI.</span>
          </h2>
          <img src={appsImg} alt="apps" className="img" />
        </section>
      </main>
    </>
  );
};

export default Content;
