// Contact.js
import React from 'react';
import '../styles/Contact.css';
import Header from './Header';

const Contact = () => {
    return (
        <div className="contact-page">
            <Header onZuriaClick={() => (window.location.href = '/')} />
            <div className="contact-container">
                <h1 className="title_contact">Contact us</h1>
                <div className="tomo">
                    <h1>Tomo</h1>
                    <h4 className="contact_text"><span className="bold_c">cofounder - sales | </span>tomo@getzuria.com</h4>
                </div>
                <div className="sid">
                    <h1>Sid</h1>
                    <h4 className="contact_text"><span className="bold_c">cofounder - software | </span>sid@getzuria.com</h4>
                </div>
            </div>
        </div>
    );
};

export default Contact;
